html, body, #root {
  height: 100vh;
}
#root {
  margin: 0;
  font-family: Poppins, Helvetica, sans-serif !important;
  background: url(assets/hero-bg.jpg) top center;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus{
  outline: 0 !important;
  box-shadow: none !important;
}